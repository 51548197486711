.testimonials-section {
  padding: 60px 20px;
  background-color: #E0E0E0; /* Light grey background */
  overflow: hidden; /* Ensure the section doesn't overflow */
}

.testimonial-container {
  max-width: 1200px;
  margin: 0 auto;
  overflow: visible;
  position: relative;
}

.testimonials-section h2 {
  text-align: center;
  font-size: 2.5rem;
  color: #333333;
  margin-bottom: 40px;
}

.video-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.video-grid {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.video-card {
  flex: 0 0 calc(100% / 6.5);/* Set each card to take up exactly 25% of the grid */
  max-width: calc(100% / 3);
  margin: 0 30px; /* Adjust margins if necessary */
  background-color: #FFFFFF;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  opacity: 0.5;
  transform: scale(0.85);
  transition: transform 0.5s ease, opacity 0.5s ease;
  pointer-events: none;
}

.video-card.active {
  transform: scale(1);
  opacity: 1;
  pointer-events: auto;
}

.video-card video {
  z-index: 5; /* Ensure the video is visible */
  width: calc(100% - 0px);
  height: auto;
  margin: 20px auto 0;
  border-radius: 8px;
  display: block;
}

.play-pause-overlay {
  z-index: 10; /* Ensure it's above the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
}

.play-icon {
  width: 50px;
  height: 50px;
}

/* Arrow Styles */
.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  width: 60px;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 15; /* Make sure it's on top */
}

.arrow-left {
  left: -40px; /* Position the arrow further to the left of the container */
  top: 60%;
  background-image: url('../src/utils/images/left-arrow.png');
}

.arrow-right {
  right: 20px; /* Position the arrow further to the right */
  top: 60%;
  background-image: url('../src/utils/images/right-arrow.png');
}

/* Mobile View Styles */
@media (max-width: 768px) {
  .testimonials-section-mobile {
    padding: 60px 20px;
    background-color: #E0E0E0;
    overflow: hidden;
    position: relative;
  }

  .testimonial-container-mobile {
    max-width: 100%;
    margin: 0 auto;
    position: relative;
  }

  .testimonials-title-mobile {
    text-align: center;
    font-size: 1.5rem;
    color: #333333;
    margin-bottom: 40px;
  }

  .video-slider-mobile {
    overflow: hidden;
    width: 100%;
    position: relative;
  }

  .video-grid-mobile {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 600%; /* Manually set this for now to ensure all videos are accounted for */
  }

  .video-card-mobile {
    flex: 0 0 calc(50%/5); /* Each video card takes full width */
    max-width: calc(100%/3);
    margin: 0 10px;
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    position: relative;
    overflow: hidden; /* Ensure the overlay stays within the bounds */
  }

  .video-element-mobile {
    width: 100%;
    height: auto;
    max-height: 300px;
    border-radius: 8px;
  }

  .play-pause-overlay-mobile {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    z-index: 20; /* Ensure it's above the video */
    padding: 10px;
    border-radius: 50%; 
  }

  .play-icon-mobile {
    width: 50px;
    height: 50px;
  }

}

.testimonials-review-section {
    padding: 60px 20px;
    background-color: #f7f7f7; /* Light grey background to contrast */
  }
  
  .testimonials-review-section h2 {
    text-align: center;
    font-size: 2.5rem;
    color: #333333;
    margin-bottom: 40px;
  }
  
  .reviews-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .review-card {
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .review-card:hover {
    transform: translateY(-10px);
  }
  
  .review-text {
    font-size: 1.2rem;
    color: #555555;
    line-height: 1.6;
  }
  
  .review-author {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 15px;
    color: #FF7F27; /* Orange color to match the theme */
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .reviews-container {
      flex-direction: column;
      align-items: center;
    }
  
    .review-card {
      width: 90%;
    }
  
    .testimonials-review-section h2 {
      font-size: 2rem;
    }
  }
  
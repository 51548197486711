.blog-page {
    margin-top: 100px;
    padding: 20px;
  }
  
  .blog-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .blog-header h1 {
    font-size: 2.5rem;
  }
  
  .blog-header p {
    font-size: 1.2rem;
    color: #4d4d4d;
  }
  
  .blog-section {
    display: flex;
    /* flex-wrap: wrap; Allow wrapping for smaller screens */
    gap: 20px;
    justify-content: space-between;
  }
  
  .blog-card {
    display: block;
    text-decoration: none;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    color: #000;
    transition: transform 0.2s, box-shadow 0.2s;
    flex: 1 1 calc(48% - 20px); /* Two cards per row with space */
  }
  
  .blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  }
  
  .blog-card img {
    width: 100%;
    aspect-ratio: 16 / 6;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .blog-card h3 {
    font-size: 1.5rem;
    margin: 15px 0;
  }
  
  .blog-card p {
    color: #4d4d4d;
    font-size: 1rem;
  }
  
  .read-more {
    color: #ff7f27;
    text-decoration: none;
    font-weight: bold;
  }
  
  /* Responsive Styling */
  @media (max-width: 1024px) {
    .blog-card {
      flex: 1 1 calc(48% - 10px); /* Slightly narrower on smaller screens */
    }
  }
  
  @media (max-width: 768px) {
    .blog-section {
      flex-direction: column; /* Stack cards vertically */
    }
  
    .blog-card {
      flex: 1 1 100%; /* Full width for each card */
    }
  
    .blog-header h1 {
      font-size: 2rem;
    }
  
    .blog-header p {
      font-size: 1rem;
    }
  
    .blog-card h3 {
      font-size: 1.3rem;
    }
  
    .blog-card p {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 480px) {
    .blog-page {
      padding: 10px;
    }
  
    .blog-header h1 {
      font-size: 1.8rem;
    }
  
    .blog-header p {
      font-size: 0.9rem;
    }
  
    .blog-card {
      padding: 15px;
    }
  
    .blog-card h3 {
      font-size: 1.2rem;
    }
  
    .blog-card p {
      font-size: 0.9rem;
    }
  }
  
.blog-detail {
    margin-top: 120px;
    padding: 20px;
    line-height: 1.6;
  }
  
  .blog-detail-image {
    width: 50%;
    aspect-ratio: 16 / 6;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .blog-detail h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .blog-detail h3 {
    color: #4d4d4d;
    margin-bottom: 20px;
  }
  
  .blog-detail p {
    line-height: 1.6;
    color: #333333;
    margin-bottom: 15px;
    font-size: 1.1rem;
  }
  
  .blog-content h2 {
    font-size: 1.5rem;
    color: #000000;
    font-weight: bold;
    margin: 20px 0 10px;
  }
  
  .blog-content ul {
    margin: 15px 0;
    padding-left: 20px;
  }
  
  .blog-content ul li {
    margin-bottom: 10px;
    font-size: 1.1rem;
    color: #4d4d4d;
  }
  
  .blog-content strong {
    font-weight: bold;
  }
  
  /* Responsive Styling */
  @media (max-width: 768px) {
    .blog-detail {
      padding: 15px;
    }
  
    .blog-detail h1 {
      font-size: 2rem;
    }
  
    .blog-detail h3 {
      font-size: 1.2rem;
    }
  
    .blog-detail p {
      font-size: 1rem;
    }
  
    .blog-content h2 {
      font-size: 1.3rem;
    }
  }
  
  @media (max-width: 480px) {
    .blog-detail {
      padding: 10px;
    }

    .blog-detail-image {
        width: 100%;
        aspect-ratio: 16 / 6;
        object-fit: cover;
        border-radius: 8px;
      }
  
    .blog-detail h1 {
      font-size: 1.8rem;
    }
  
    .blog-detail h3 {
      font-size: 0.8rem;
    }
  
    .blog-detail p {
      font-size: 0.9rem;
    }
  
    .blog-content h2 {
      font-size: 1rem;
      line-height: 1;
    }
  }
  
.navbar {
  width: 100%;
  background-color: #FFFFFF; /* White background for a clean look */
  padding: 15px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed; /* Stick to the top */
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure the navbar is on top of other elements */
  transition: background-color 0.3s ease, padding 0.3s ease; /* Smooth transition for background and padding */
}

.navbar.scrolled {
  background-color: #f9f9f9; /* Lighter background on scroll */
  padding: 10px 0; /* Slightly reduce padding on scroll for a compact look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Increase shadow for visibility */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1100px; /* Slightly reduced width to bring elements closer */
  margin: 0 auto;
  padding: 0 20px;
}

.logo img {
  display: block;
  max-height: 90px; /* Slightly reduced the logo size */
  margin-right: 20px; /* Reduced margin for tighter look */
}

.nav-links {
  display: flex;
  gap: 30px; /* Reduced gap for closer spacing */
}

.nav-links a {
  text-decoration: none;
  color: #4D4D4D; /* Dark Gray for text */
  font-size: 1rem;
  font-weight: 600;
  position: relative;
  padding-bottom: 5px;
}

.nav-links a.active {
  color: #FF7F27; /* Orange for active link */
}

.nav-links a.active::after {
  content: '';
  width: 30px; /* Wider underline */
  height: 2px;
  background-color: #FF7F27; /* Orange underline for active link */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -3px; /* Slightly moved closer to the text */
}

.menu-icon {
  display: none;
  cursor: pointer;
  font-size: 24px;
  color: #4D4D4D; /* Dark Gray for icon */
  z-index: 1000; /* Ensure the icon is on top */
}

.btn-consultation {
  display: flex;
  align-items: center;
  padding: 8px 20px; /* Adjusted padding for better alignment */
  background-color: #FF7F27; /* Orange for button */
  color: #FFFFFF; /* White text */
  text-decoration: none;
  border-radius: 25px; /* Rounded corners */
  font-weight: 600;
  margin-left: 10px; /* Reduced margin for tighter spacing */
}

@media (max-width: 768px) {
  .nav-links {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 250px;
    background-color: #FFFFFF;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 60px;
    gap: 20px;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 999; /* Ensure the menu is below the icon */
  }

  .nav-links.open {
    transform: translateX(0);
  }

  .nav-links a {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.2rem;
  }

  .nav-links a.active::after {
    bottom: 5px; /* Adjust the spacing for mobile */
    width: 40px; /* Slightly wider underline for mobile view */
    left: 15%;
  }

  .menu-icon {
    display: block;
  }

  .btn-consultation {
    display: none; /* Hide consultation button on mobile */
  }
}

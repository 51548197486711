.contact-details {
    display: flex;
    justify-content: space-between; /* Align items to the right */
    align-items: center;
    padding: 10px 20px;
    background-color: #F2F2F2; /* Light Gray */
    flex-wrap: wrap; /* Allows wrapping for smaller screens */
  }
  
  /* Hide the entire ContactDetails section on smaller screens */
  @media (max-width: 600px) {
    .contact-details {
        display: none !important; /* Use !important to ensure this rule takes precedence */
    }
  }
  
  .social-icons {
    display: flex;
    align-items: left;
    margin-bottom: 10px; /* Margin for smaller screens */
  }
  
  .social-icons a {
    text-decoration: none; /* Removes underline from links */
    color: inherit; /* Inherits color from icon style */
    margin-right: 15px;
    font-size: 18px;
    cursor: pointer;
  }
  
  .contact-details-info .contact-icon {
    color: #4D4D4D; /* Dark Gray */
    margin-right: 8px;
    font-size: 18px;
    vertical-align: middle; /* Align the icon with the text */
  }
  
  .contact-details-info .contact-text {
    color: #4D4D4D; /* Dark Gray */
    margin-right: 20px;
    font-size: 14px;
    vertical-align: middle; /* Align the text with the icon */
    line-height: 1.2; /* Adjust line height for better alignment */
  }
  
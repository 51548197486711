.hero-section {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 160px 20px; /* Adjusted padding to account for the fixed navbar */
    width: 100%;
    max-width: 100%;
    background-color: #FFFFFF; /* White background for clean look */
    background-image: url('./utils/images/liberty.jpg'); /* Path to your background image */
    background-size: cover; /* Ensure the background image covers the entire section */
    background-position: center; /* Center the background image */
    overflow: hidden;
}

/* Animations */
@keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(50px); /* Small vertical movement to prevent content going out of view */
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

.hero-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    position: relative; /* Keep the content above the background */
    z-index: 2; /* Ensure content appears above the background image */
    overflow: hidden;
}

.hero-content {
    max-width: 45%;
    text-align: left;
    color: #000000; /* Ensure text is readable over the background */
    animation: fadeInUp 1.5s ease-in-out forwards;
    animation-delay: 0.5s; /* Delay to make it smooth */
    overflow: hidden; /* Ensure no overflow while animations are running */
}

.hero-content h1 {
    font-size: 3rem; /* Default font size */
    font-weight: bold;
    color: #000000;
    text-align: left;
    opacity: 0; /* Initially hidden */
    animation: fadeInUp 1s ease-in-out forwards;
    animation-delay: 0.5s; /* Starts after 0.5 seconds */
}

@media (min-width: 1024px) {
    .hero-content h1 {
      font-size: 2.8rem; /* Slightly smaller font size on larger screens */
      white-space: normal; /* Prevents the text from wrapping to the next line */
    }
  }

    .hero-content p {
    font-size: 1.5rem;
    color: #4D4D4D;
    line-height: 1.6;
    margin-bottom: 20px;
    opacity: 0; /* Initially hidden */
    animation: fadeInUp 1s ease-in-out forwards;
    animation-delay: 1s; /* Starts after 1 second */
}

.home-page-btn {
    display: inline-block;
    padding: 15px 30px;
    background-color: #FF7F27;
    color: #FFFFFF;
    text-decoration: none;
    border-radius: 8px;
    font-size: 1.2rem;
    font-weight: 600;
    transition: background-color 0.3s ease;
    opacity: 0; /* Initially hidden */
    animation: fadeInUp 1s ease-in-out forwards;
    animation-delay: 1.5s; /* Starts after 1.5 seconds */
}

.btn-primary:hover {
    background-color: #e06c21;
}

.hero-image {
    max-width: 48%;
    margin-left: 10px;
    overflow: hidden;
}

.hero-image img {
    max-width: 100%;
    height: auto;
    border-radius: 20px;
    opacity: 0; /* Initially hidden */
    animation: fadeInUp 1s ease-in-out forwards;
    animation-delay: 0.5s; /* Starts after 0.5 seconds */
  }

.hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8); /* White overlay for readability */
    z-index: 1; /* Overlay sits above the background image but below content */
}   


    @media (max-width: 768px) {
        .hero-section {
            width: auto;
            padding: 150px 20px; /* Adjust padding for mobile */
            text-align: left; /* Align text to the left */
            flex-direction: column; /* Stack elements vertically */
            align-items: flex-start; /* Align content to the left */
        }
    
        .hero-container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start; /* Align the content to the left */
        }
    
        .hero-image {
            margin-bottom: 15px;
            width: 100%; /* Ensure the image fits the container */
            display: flex;
            justify-content: flex-start; /* Align image to the left */
            max-width: 80%;
        }
    
        .hero-image img {
            width: 100%; /* Allow the image to take full width */
            height: auto;
            max-width: 110%; /* Ensure the image does not exceed container width */
            border-radius: 10px;
            object-fit: cover;
        }
    
        .hero-content {
            text-align: left; /* Keep text left-aligned */
            max-width: 100%; /* Allow the content to take full width */
            font-size: 2rem;
            padding-left: 0; /* Remove padding */
        }
    
        .hero-content h1 {
            font-size: 1.8rem; /* Adjust heading size */
            line-height: 1.2; /* Ensure proper spacing between lines */
            margin-bottom: 10px; /* Space between heading and paragraph */
        }
    
        .hero-content p {
            font-size: 1.1rem; /* Adjust paragraph size */
            color: #4D4D4D;
            margin-bottom: 20px; /* Space between paragraph and button */
        }
    
        .home-page-btn {
            padding: 10px 20px;
            font-size: 1rem;
        }
    }
/* Footer section overall styling */
.footer-section {
  background-color: #333333; /* Dark background */
  color: #f0f0f0; /* Light color for text contrast */
  padding: 40px 15px; /* Less padding for smaller screens */
  font-family: 'Arial', sans-serif;
  margin-top: 50px; /* Adds space between the content and footer */
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
}

.footer-content {
  display: flex;
  justify-content: center; /* Ensure content is centered */
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 40px;
  margin-bottom: 40px;
}

.footer-about,
.footer-links,
.footer-contact {
  flex: 1;
  min-width: 220px;
  text-align: center; /* Center the text in all sections */
}

.footer-logo {
  max-width: 120px; /* Reduce the logo size for mobile */
  margin-bottom: 20px;
  display: block; /* Ensure logo is centered */
  margin-left: auto;
  margin-right: auto;
}

.footer-about p {
  font-size: 0.9rem; /* Smaller font size for mobile */
  line-height: 1.4;
  color: #cccccc; /* Light gray for contrast */
  margin-bottom: 20px; /* Add more spacing below paragraphs */
}

.footer-links h3,
.footer-contact h3 {
  font-size: 1.25rem;
  margin-bottom: 15px;
  color: #ffffff;
}

.footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0 auto; /* Ensure the list is centered */
  text-align: center; /* Center the list items */
}

.footer-links ul li {
  margin-bottom: 10px;
}

.footer-links ul li a {
  color: #f0f0f0;
  text-decoration: none;
  font-size: 1rem;
  transition: color 0.3s;
}

.footer-links ul li a:hover {
  color: #FF7F27; /* Orange hover effect */
}

.footer-contact p {
  margin-bottom: 10px;
  font-size: 0.9rem;
  color: #f0f0f0;
}

.footer-contact i {
  margin-right: 10px;
}

.footer-bottom {
  display: flex;
  justify-content: center; /* Center the content */
  align-items: center;
  padding-top: 20px;
  border-top: 1px solid #555555; /* Lighter border for separation */
  flex-direction: column;
  text-align: center;
}

.footer-bottom p {
  margin: 0;
  font-size: 0.85rem;
  color: #cccccc;
  margin-bottom: 10px;
}

.social-icons-footer {
  margin-top: 10px;
}

.social-icons-footer a {
  color: #f0f0f0;
  margin-left: 10px;
  font-size: 1.2rem;
  transition: color 0.3s;
}

.social-icons-footer a:hover {
  color: #FF7F27;
}

/* Additional Mobile Styling */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center; /* Ensure the text and content are centered */
  }

  .footer-about,
  .footer-links,
  .footer-contact {
    width: 100%; /* Ensure sections take up full width */
    text-align: center; /* Ensure all text is centered */
    margin-bottom: 20px; /* Add spacing between sections */
  }

  .footer-links ul {
    margin: 0 auto; /* Center the list */
    text-align: center; /* Ensure list items are centered */
  }

  .footer-links ul li {
    margin-bottom: 10px;
  }

  .footer-bottom {
    text-align: center; /* Ensure text is centered in mobile */
  }

  .social-icons-footer {
    margin-top: 10px;
  }
}

.services-section {
    padding: 60px 20px;
    background-color: #1E1E1E; /* Dark background color */
}

.service-container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.services-section h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #FFFFFF; /* White text color for contrast */
}

.services-section p {
    font-size: 1rem;
    margin-bottom: 40px;
    color: #CCCCCC; /* Light grey text color */
}

.services-grid {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.service-card {
    background-color: #292929; /* Slightly lighter dark background */
    padding: 20px;
    border-radius: 12px;
    text-align: center;
    width: 22%;
    min-width: 250px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease;
}

.service-card:hover {
    transform: translateY(-10px); /* Lift the card on hover */
}

.service-card img {
    color: #fff;
    width: 50px;
    margin-bottom: 15px;
}

.service-card h3 {
    font-size: 1.3rem;
    margin-bottom: 10px;
    color: #FFFFFF; /* White text color */
    font-weight: 400;
}

.service-card p {
    font-size: 1rem;
    color: #CCCCCC; /* Light grey text color */
}

.service-card .view-more {
    margin-top: 15px;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 8px;
    background-color: #3A3A3A; /* Button background color */
    color: #FFFFFF; /* White text color */
    text-decoration: none;
    display: inline-block;
    transition: background-color 0.3s ease;
}

.service-card .view-more:hover {
    background-color: #FF7F27; /* Orange background on hover */
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
    .service-card {
        width: 45%;
    }
}

@media (max-width: 768px) {
    .services-grid {
        flex-direction: column;
    }

    .service-card {
        width: calc(100% - 40px);
        margin-bottom: 20px;
    }

    .services-section h2 {
        font-size: 2rem;
    }

    .services-section p {
        font-size: 1rem;
    }
}


.featured-podcast {
    padding: 60px 20px;
    background-color: #f9f9f9; /* Light grey background */
    text-align: center;
    margin: 40px 0;
  }
  
  .featured-podcast .container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .podcast-title {
    font-size: 2rem;
    color: #333; /* Dark grey for titles */
    margin-bottom: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .podcast-description {
    font-size: 1rem;
    color: #555; /* Medium grey for text */
    margin-bottom: 40px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .podcast-video iframe {
    width: 100%;
    max-width: 700px;
    height: 400px;
    border-radius: 8px; /* Slightly rounded corners for elegance */
    border: none; /* Remove any default borders */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  }

  @media (max-width: 768px) {
    .podcast-title {
      font-size: 1.5rem; /* Adjust title size for mobile */
    }
  
    .podcast-description {
      font-size: 0.9rem; /* Adjust description size for mobile */
      margin-bottom: 30px;
    }
  
    .podcast-video iframe {
      height: 200px; /* Maintain aspect ratio */
      max-width: 100%;
    }
  }
  
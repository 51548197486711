/* General Styling */
.contact-section-redesigned {
    padding: 60px 20px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1100px;  /* Restrict the maximum width */
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 40px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    gap: 40px;
}

/* Contact Info Styling */
.contact-info-redesigned {
    text-align: center;
    margin-bottom: 40px;
}

.contact-info-redesigned h2 {
    font-size: 2.2rem;
    color: #333;
    margin-bottom: 10px;
}

.contact-info-redesigned p {
    font-size: 1.1rem;
    line-height: 1.8;
    color: #555;
}

.contact-details-redesigned p {
    font-size: 1.1rem;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-details-redesigned p i {
    margin-right: 10px;
    color: #ff7f27;
}

/* Form Styling */
.contact-form-container-redesigned {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Ensure full width */
}

.contact-form-container-redesigned h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
}

.contact-form-redesigned {
    width: 100%;
    max-width: 600px;  /* Set a maximum width */
    display: flex;
    flex-direction: column;
}

.contact-form-redesigned input,
.contact-form-redesigned textarea {
    width: 100%;
    padding: 12px 15px;  /* Adjust padding */
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1.1rem;
    background-color: #fafafa;
    box-sizing: border-box; /* Include padding in width */
}

.contact-form-redesigned textarea {
    resize: vertical;
}

.contact-form-redesigned input:focus,
.contact-form-redesigned textarea:focus {
    outline: none;
    border-color: #ff7f27;
}

.contact-form-redesigned button {
    background-color: #ff7f27;
    color: white;
    padding: 15px 25px;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}

.contact-form-redesigned button:hover {
    background-color: #e06c21;
}

/* Thank You Message */
.thank-you-overlay-redesigned {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.thank-you-card-redesigned {
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
    text-align: center;
    color: #333;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .contact-wrapper {
        padding: 20px;
    }

    .contact-info-redesigned h2 {
        font-size: 1.8rem;
    }

    .contact-form-redesigned input,
    .contact-form-redesigned textarea {
        font-size: 1rem;
    }
}

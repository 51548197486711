/* General Styles */
body {
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4; /* Light background for contrast */
}

/* Hero Section */
.about-hero {
  position: relative;
  background-image: url('./utils/images/hero-bg.webp'); /* Replace with actual hero image */
  background-size: cover;
  background-position: center;
  padding: 150px 20px;
  text-align: center;
  color: #fff;
  z-index: 1; /* Ensure the content is on top */
  overflow: hidden; /* For smooth edge control */
}

.about-hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darker overlay for better contrast */
  z-index: -1;
}

/* Animations */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(0px); /* Small vertical movement to prevent content going out of view */
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.about-hero h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 10px;
  opacity: 0; /* Initially hidden */
  animation: fadeInUp 1s ease-in-out forwards;
  animation-delay: 0.6s; /* Starts after 0.5 seconds */
}

.about-hero p {
  font-size: 1.1rem;
  margin-top: 10px;
  line-height: 1.6;
  max-width: 800px;
  margin: 10px auto 0;
  opacity: 0; /* Initially hidden */
  animation: fadeInUp 1s ease-in-out forwards;
  animation-delay: 0.5s; /* Starts after 0.5 seconds */
}

/* About Section */
.about-section {
  padding: 60px 20px;
  background-color: #f8f9fa;
  text-align: center;
}

.about-section h2 {
  font-size: 2.8rem;
  margin-bottom: 20px;
}

.about-section p {
  font-size: 1.25rem;
  line-height: 1.8;
  color: #333;
  max-width: 900px;
  margin: 0 auto;
}

/* Leadership Section */
.leadership-section {
  padding: 80px 20px; /* Increased padding for better spacing */
  background-color: #f9f9f9; /* Light background color for contrast */
  text-align: center;
}

.leadership-heading {
  font-size: 3rem;
  font-weight: 700;
  color: #333333; /* Darker color for strong emphasis */
  margin-bottom: 40px;
}

.leadership-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 40px; /* Increase gap between image and text */
  max-width: 1200px;
  margin: 0 auto;
}

.leadership-image-container {
  flex: 1;
  max-width: 400px; /* Ensure image takes about 40-50% width */
}

.leadership-image-container img {
  width: 100%;
  height: auto;
  border-radius: 12px; /* Round the corners slightly */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

.leadership-details {
  flex: 2; /* Allow text to take up more space */
  max-width: 700px;
  text-align: left; /* Align text to the left for readability */
}

.leadership-details h3 {
  font-size: 2.5rem;
  margin-bottom: 10px;
  color: #333;
}

.leadership-details p {
  font-size: 1.15rem;
  line-height: 1.8; /* Improve readability */
  color: #555555;
  margin-bottom: 20px; /* Add spacing between paragraphs */
}

.leadership-bio p {
  margin-bottom: 20px; /* Ensure proper paragraph spacing */
}

/* Call to Action Section */
.cta-section {
  padding: 60px 20px;
  background-color: #333;
  color: #fff;
  text-align: center;
}

.cta-container h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.cta-container p {
  font-size: 1.25rem;
  margin-bottom: 30px;
}

.aboutus-btn {
  display: inline-block;
  padding: 15px 30px;
  background-color: #FF7F27;
  color: #fff;
  text-decoration: none;
  border-radius: 8px;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #e06c21;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .leadership-container {
    flex-direction: column;
  }

  .leadership-image-container {
    margin-bottom: 30px;
  }

  .leadership-details {
    text-align: center;
  }

  .leadership-bio {
    text-align: left;
  }

  .leadership-heading {
    font-size: 2.5rem; /* Adjust heading size for mobile */
  }
}

@media (max-width: 768px) {
  .about-hero h1 {
    font-size: 2.8rem;
  }

  .about-hero p {
    font-size: 1.4rem;
  }

  .leadership-heading {
    font-size: 2.2rem;
  }

  .leadership-details h3 {
    font-size: 2rem;
  }

  .leadership-details p {
    font-size: 1.1rem;
  }

  .cta-container h2 {
    font-size: 2.2rem;
  }
}

@media (max-width: 480px) {
  .about-hero h1 {
    font-size: 2rem;
    margin-bottom: 2px;
  }

  .about-hero p {
    font-size: 1rem;
  }

  .about-section h2 {
    font-size: 1.8rem;
  }

  .about-section p {
    font-size: 1rem;
  }

  .leadership-heading {
    font-size: 2rem;
  }

  .leadership-details h3 {
    font-size: 1.8rem;
  }

  .leadership-details p {
    font-size: 1rem;
  }

  .cta-container h2 {
    font-size: 2rem;
  }

  .cta-container p {
    font-size: 1rem;
  }

  .aboutus-btn{
    font-size: 1rem;
  }
}

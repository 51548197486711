/* Contact Us Section */
.contact-us-section {
    position: relative;
    z-index: 1; 
    background-color: #ffffff;
    padding: 150px 20px; /* Reduced padding for mobile responsiveness */
    font-family: 'Arial', sans-serif;
}

.thank-you-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Darker overlay for better contrast */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
.thank-you-card {
    background-color: #ffffff;
    padding: 40px 20px; /* Adjusted padding for a balanced look */
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4);
    text-align: center;
    font-size: 1.2rem; /* Adjusted font size */
    color: #333333;
    max-width: 500px;
    width: 90%;
    line-height: 1.6;
}

.thank-you-card p {
    margin: 0;
    padding: 0;
    font-weight: 500;
}

.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-form-container, .contact-map-container {
    max-width: 600px; /* Adjusted width */
    margin: 0 auto; /* Center horizontally */
    padding: 30px;
    background-color: #f8f8f8;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: center;

}

.contact-form-container {
    max-width: 600px; /* Adjust maximum width to avoid overflow */
    margin: 0 auto;
}

.contact-form-container h2, .contact-map-container h3 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
    text-align: center; /* Centered text for better alignment */
}

.contact-form-container p {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 25px;
    text-align: center; /* Centered text */
}

.contact-form label {
    display: block;
    margin-bottom: 8px;
    font-size: 1.1rem;
    color: #333;
}

.contact-form input, .contact-form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.contact-form textarea {
    resize: none;
}

.contact-form .btn-submit {
    background-color: #FF7F27;
    color: #ffffff;
    padding: 12px 25px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.contact-form .btn-submit:hover {
    background-color: #e06c21;
}

.contact-map-container h3 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    text-align: center;
}

.contact-map-container p {
    font-size: 1.1rem;
    color: #666;
    margin-top: 15px;
    text-align: center; /* Centered address text */
}

.google-map {
    width: 100%;
    height: 400px;
    margin-top: 10px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Responsive Styling */
@media (max-width: 768px) {
    .contact-container {
        padding: 15px;
        background-color: #ffffff;
    }

    .contact-form-container, .contact-map-container {
        width: 100%; /* Full width for mobile */
        max-width: none; /* No max-width to ensure it fits */
        padding: 30px; /* Adjust padding for mobile */
        margin: 0 10px; /* Add some space on both sides */
        font-size: 1rem;
    }

    .contact-form-container h2, .contact-map-container h3 {
        text-align: center;
        font-size: 1.8rem;
    }

    .contact-form-container .btn-submit {
        width: 100%; /* Button takes full width in mobile view */
    }

    .google-map {
        height: 300px;
    }

    .thank-you-card {
        padding: 20px 15px; /* Adjust padding for smaller screens */
        font-size: 1rem; /* Adjust font size for smaller screens */
    }
}

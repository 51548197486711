body,  p, span, li, div , html {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400; /* Regular weight for body text */
  overflow-x: hidden;
}

h1, h2, h4, h5, h6 {
  font-family: 'Playfair Display SC', serif;
  font-weight: 700; /* Set font weight as per your preference */
}

h1 {
  font-family: 'Playfair Display SC', serif;
  font-weight: 700; /* Bold for h1 */
  font-size: 2.5rem; /* Adjust size for h1 */
}

h2 {
  font-family: 'Playfair Display SC', serif;
  font-weight: 600; /* Slightly lighter for h2 */
  font-size: 2rem; /* Adjust size for h2 */
}
p {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400; /* Regular weight */
  font-size: 1rem;  /* Default size for paragraphs */
}

/* Set a bolder weight for emphasis or headers inside paragraphs */
strong {
  font-weight: 700; /* Bold weight for strong text */
}

/* Styling for list items */
li {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400; /* Regular weight */
}

/* Customize the font for spans inside paragraphs */
span {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
}



/* You can continue for h4, h5, h6 */

/* General Page Styling */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(0px); /* Small vertical movement to prevent content going out of view */
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.expertise-section {
    padding: 150px 20px;
    background-color: #f9f9f9;
    font-family: 'Arial', sans-serif;
    opacity: 0; /* Initially hidden */
    animation: fadeInUp 1s ease-in-out forwards;
    animation-delay: 0.6s; /* Starts after 0.5 seconds */
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Heading */
  .expertise-heading {
    text-align: center;
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 50px;
  }
  
  /* Expertise Categories */
  .expertise-category {
    margin-bottom: 40px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .expertise-category h2 {
    font-size: 2rem;
    color: #C4151E; /* Brand color */
    margin-bottom: 20px;
    border-bottom: 2px solid #C4151E;
    padding-bottom: 10px;
  }
  
  .category-content {
    padding-left: 20px;
  }
  
  .category-content h3 {
    font-size: 1.5rem;
    color: #555;
    margin-top: 20px;
  }
  
  .category-content p {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.8;
    margin-bottom: 10px;
  }
  
  /* Button Styling */
  .btn-primary {
    display: inline-block;
    padding: 15px 30px;
    background-color: #C4151E;
    color: white;
    border-radius: 8px;
    font-size: 1.2rem;
    text-decoration: none;
    margin-top: 40px;
  }
  
  .btn-primary:hover {
    background-color: #a01218;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .expertise-heading {
      font-size: 2rem;
    }
  
    .expertise-category h2 {
      font-size: 1.8rem;
    }
  
    .category-content h3 {
      font-size: 1.3rem;
    }
  
    .category-content p {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .expertise-heading {
      font-size: 1.8rem;
    }
  
    .expertise-category h2 {
      font-size: 1.5rem;
    }
  
    .category-content h3 {
      font-size: 1.2rem;
    }
  
    .category-content p {
      font-size: 0.95rem;
    }
  }
  
/* Animations */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(0px); /* Small vertical movement to prevent content going out of view */
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.case-study-page {
    padding: 90px 20px;
    background-color: #f8f8f8;
    font-family: 'Arial', sans-serif;
    opacity: 0; /* Initially hidden */
    animation: fadeInUp 1s ease-in-out forwards;
    animation-delay: 0.6s; /* Starts after 0.5 seconds */
  }

  .case-study-page h1 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 40px;
    color: #333;
  }
  
  .case-study-page p {
    text-align: left;
    font-size: 1.2rem;
    margin-bottom: 40px;
    color: #666;
  }
  
  /* Case Study container */
  .case-study {
    background-color: #ffffff;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }
  
  /* Header of each case study with arrow */
  .case-study-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    background-color: #f0f0f0;
    border-bottom: 1px solid #ddd;
    font-size: 1.2rem;
    font-weight: 600;
    color: #333;
    position: relative;
    overflow: hidden;
  }
  
  .case-study-header h3 {
    font-size: 1.3rem;
    margin: 0;
    color: #333;
    text-transform: capitalize; /* Proper capitalization */
    flex: 1;
  }
  
  .case-study-header .arrow {
    font-size: 1rem; /* Reduced arrow size */
    transition: transform 0.5s ease-in-out;
    position: absolute; /* Position it absolute to the right */
    right: 10px; /* Align the arrow to the right */
    top: 90%; /* Vertically center the arrow */
    transform: translateY(-50%); /* Ensure vertical centering */
  }
  
  .case-study-header:hover {
    background-color: #e6e6e6; /* Slight hover effect */
  }
  
  .case-study-header .arrow.open {
    top: -50%;
    transform: rotate(180deg); /* Rotate arrow when open */
  }
  
  /* Case study content, initially hidden */
  .case-study-content {
    max-height: 0;
    overflow: hidden;
    opacity: 0;
    padding: 0 15px;
    transition: max-height 0.5s ease, opacity 0.5s ease;
    font-size: 1rem;
  }
  
  .case-study-content.open {
    max-height: 500px; /* Large enough for content */
    opacity: 1;
    padding: 20px 15px;
  }
  
  .case-study-content h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    color: #555;
    font-weight: bold;
  }
  
  .case-study-content p {
    font-size: 1rem;
    line-height: 1.6;
    color: #444;
    margin-bottom: 10px;
  }
  
  .case-study-content strong {
    color: #000;
  }
  
  /* Smooth border-radius transition when opening and closing */
  .case-study-content.open,
  .case-study-header {
    border-radius: 8px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .case-study-page {
      padding: 150px 15px;
    }

    .case-study-page h1 {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 40px;
    }
  
    .case-study-header h2 {
      font-size: 1.2rem;
    }
  
    .case-study-content h3 {
      font-size: 1.1rem;
    }

    .case-study-content {
        overflow-y: auto;  /* Enable scrolling when content exceeds */
        scroll-behavior: smooth; /* Smooth scroll effect */
    }

    .case-study-content::-webkit-scrollbar {
        width: 5px; /* Make scrollbar thinner for a clean look */
      }
    
      .case-study-content::-webkit-scrollbar-thumb {
        background-color: #ccc; /* Slightly visible scrollbar */
        border-radius: 10px;
      }
    
      .case-study-content.open {
        max-height: 400px; /* Increase height for opened content */
      }

    .case-study-content p {
      font-size: 1rem;
    }
  
    .case-study-header .arrow {
        font-size: 1.3rem; /* Slightly reduce the size of the arrow for mobile */
        right: 10px; /* Align arrow properly on smaller screens */
    }
  }
  